<template>
  <section class="section is-paddingless-horizontal" style="margin-top: 70px">
    <div class="container">
      <div class="block center">
        <div class="tags">
          <span class="tag is-info is-light">ReactJS</span>
          <span class="tag is-info is-light">PostgreSQL</span>
          <span class="tag is-info is-light">Node.js</span>
          <span class="tag is-info is-light">Express</span>
        </div>
      </div>

      <div
        class="section is-paddingless-horizontal"
        style="padding-top: 0px; padding-bottom: 10px"
      >
        <h1 class="title is-2">Marquee</h1>
        <h2 class="subtitle is-5 mt-1">
          Developing a one-stop platform for all things furniture-related
        </h2>
      </div>
    </div>
    <div class="section">
      <figure class="image card is-mobile is-marginless">
        <img src="../assets/banners/a5.png" alt="" />
      </figure>
    </div>
    <div class="container grid">
      <div class="content has-text-left">
        <h1>At a glance</h1>
        <p>
          As part of the capstone module for my degree, my team developed a
          web-based platform for furniture-buyers, home renovaters, furniture
          contactors and interior designers to transact.
        </p>
        <br />
        <table>
          <tbody>
            <tr>
              <td><strong>Role</strong></td>
              <td>
                Designer<br />
                Front-end developer<br />
              </td>
            </tr>
            <tr>
              <td><strong>Date</strong></td>
              <td>Jan - May 2022</td>
            </tr>
            <tr>
              <td><strong>Project Type</strong></td>
              <td>School Coding Project</td>
            </tr>
            <tr>
              <td><strong>Tech</strong></td>
              <td>
                ReactJS<br />
                Material UI<br />
              </td>
            </tr>
          </tbody>
        </table>
        <h1>My responsibilities</h1>
        <ul>
          <li>Design lo-fi mockup screens and logo</li>
          <li>
            Develop the Pinterest-inspired social media page, for customers to
            view home furnishing inspiration
          </li>
          <li>
            Develop moodboard functionalities, where customers can add social
            media posts to their moodboards, view, edit and delete their
            moodboards
          </li>
          <li>
            Develop functionality to view a social media post, like it, comment
            on it, delete their comment on it, and to add the social media post
            to their moodboard
          </li>
          <li>
            Develop live chat functionality for customers to chat with sellers,
            and collaborate together on their design engagement
          </li>
        </ul>
        <br />
        <br />
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left mb-6">
        <h2>Demo video</h2>
        <br />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/rwpVSc57puE?start=797"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <br />
        <br />
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Project Introduction</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m1.png" alt="" />
          </figure>
        </div>
      </div>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m2.png" alt="" />
          </figure>
        </div>
      </div>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m3.png" alt="" />
          </figure>
        </div>
      </div>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m4.png" alt="" />
          </figure>
        </div>
      </div>
      <br />
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Logo Design and Colour Scheme</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m5.png" alt="" />
          </figure>
        </div>
      </div>
      <br />
      <br />
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Social Media Page</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m6.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >View social media posts to get inspiration for home
          furnishings</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m7.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Filter for specific posts</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m8.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Save post to a new moodboard</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m9.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Save post to an existing moodboard</small
        >
      </figcaption>
      <br />
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Social Media Post</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m11.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">View social media post</small>
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m12.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Comment on social media post</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m13.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Delete comment from social media post</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m14.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">Save post to a moodboard</small>
      </figcaption>
      <br />
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Live Chat and Design Engagement</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m15.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Live chat between customer and seller</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m16.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Customer submits a request for consultation</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m17.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Seller is prompted to issue a quotation</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m18.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Customer is prompted to review the seller's quotation</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m19.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Seller uploads a new design to the design engagement with
          customer</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m20.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Review of design order progress</small
        >
      </figcaption>
      <br />
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/marquee/m21.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Customer reviews the design and rejects it</small
        >
      </figcaption>
      <br />
    </div>
  </section>
</template>

<script>
  export default {};
</script>

<style>
  .is-paddingless-horizontal {
    padding-left: 0;
    padding-right: 0;
  }

  .grid {
    display: grid;
    grid-template-columns: [xl-start] 1fr 1.5rem [md-start] minmax(0, 624px) [md-end] 1.5rem 1fr [xl-end];
  }

  .grid * {
    grid-column: md;
  }

  .grid-xl * {
    grid-column: xl;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 672px) {
    :root {
      font-size: 90%;
    }
  }
</style>
