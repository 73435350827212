<template>
  <div class="section mb-5" style="margin-top: 70px">
    <div class="columns is-centered is-multiline">
      <div class="column is-11">
        <router-link :to="{ name: 'Marquee' }">
          <div
            class="columns is-vcentered card has-background-white custom-card"
            style="padding: 20px 10px"
          >
            <div class="column is-4 px-4">
              <div class="card-image">
                <figure class=" image is-4by3">
                  <img
                    src="../assets/thumbnails/t5.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
            </div>
            <div class="column is-8 px-4 center">
              <div class="content has-text-left">
                <p class="title">Marquee</p>
                <p class="subtitle">
                  Developing a one-stop platform for all things furniture-related
                </p>
                <div class="tags">
                  <span class="tag is-info is-light">ReactJS</span>
                  <span class="tag is-info is-light">PostgreSQL</span>
                  <span class="tag is-info is-light">Node.js</span>
                  <span class="tag is-info is-light">Express</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="column is-11 mt-5">
        <router-link :to="{ name: 'FDS' }">
          <div
            class="columns is-vcentered card has-background-white custom-card"
            style="padding: 20px 10px"
          >
            <div class="column is-4 px-4">
              <div class="card-image">
                <figure class=" image is-4by3">
                  <img
                    src="../assets/thumbnails/t3.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
            </div>
            <div class="column is-8 px-4 center">
              <div class="content has-text-left">
                <p class="title">Food Delivery Service</p>
                <p class="subtitle">
                  Developing a simple food delivery service application for
                  customers, managers and restaurant staff
                </p>
                <div class="tags">
                  <span class="tag is-info is-light">VueJS</span>
                  <span class="tag is-info is-light">PostgreSQL</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="column is-11 mt-5">
        <router-link :to="{ name: 'SM' }">
          <div
            class="columns is-vcentered card has-background-white custom-card"
            style="padding: 20px 10px"
          >
            <div class="column is-4 px-4">
              <div class="card-image">
                <figure class=" image is-4by3">
                  <img
                    src="../assets/thumbnails/t4.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
            </div>
            <div class="column is-8 px-4 center">
              <div class="content has-text-left">
                <p class="title">Subscription Marketplace</p>
                <p class="subtitle">
                  Creating a subscription marketplace application where
                  customers can subscribe to products or services
                </p>
                <div class="tags">
                  <span class="tag is-info is-light">Java EE</span>
                  <span class="tag is-info is-light">JSF</span>
                  <span class="tag is-info is-light">Angular</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>