<template>
  <div class="section center" style="margin-top: 70px">
    <div class="column is-8">
      <div class="column mb-4">
        <figure class="image is-3by1">
          <img src="../assets/me.png" />
        </figure>
      </div>
      <div class="content column has-text-left">
        <h4>About Me</h4>
        <p>
          By education and her Information Systems degree, Chinn Fang's
          background is in Computing. But over time, her experiences and
          opportunities have shaped her into a well-balanced individual with
          cross-functional skills across design, tech and business domains.
        </p>
        <p>
          Chinn Fang values work ethics, and delivering quality work is
          important to her. Chinn Fang
          works best when there is a balance between team-based collaborations
          and independent work. She thrives most when she is given the space to think and create.
        </p>
        <p>
          Workplace culture is important to her. She is looking for a nurturing environment 
          that can provide mentorship and accelerate her growth while supporting her well-being.
        </p>
        <p>
          Chinn Fang is genuine, bubbly and friendly. 
          She's always up for new experiences. 
          In her free time, you can find her learning how to play squash, reading at a cafe,
          or catching up with friends and family.
        </p>
      </div>
      <div class="content column has-text-left">
        <h4>My Skills and Interests</h4>
        <div class="columns is-mobile mt-3">
          <div class="column is-4">
            <h6>Hard Skills</h6>
            <p>
              <u>Tech</u><br />
              Coding<br />
              Web development
            </p>
            <p>
              <u>Design - UX</u><br />
              User research<br />
              Wireframing<br />
              Prototyping<br />
              Usability testing
            </p>
            <p>
              <u>Design - Others</u><br />
              Product design<br />
              Slides design
            </p>
          </div>
          <div class="column is-4">
            <h6>Soft Skills</h6>
            <p>
              Diplomatic skills<br />
              Resourcefulness<br />
              Creativity<br />
              Self-directedness
            </p>
          </div>
          <div class="column is-4">
            <h6>Interests</h6>
            <p>
              Solving problems<br />
              Creating better experiences<br />
              Initiating good change<br />
              Connecting things<br />
              Ideating
            </p>
          </div>
        </div>
      </div>
      <div class="content column has-text-left">
        <h4>Some facts about me</h4>
        <p>
          I'm vegetarian (since birth!)<br />
          ENFP turned INFP turned ESTP personality<br />
          Libra sun, Virgo moon and Aries rising<br />
          Current interests include playing squash, reading, and longboarding
        </p>
      </div>
      <div class="content column has-text-left">
        <h4>Favourite book at the moment</h4>
        <p>Psycho-Cybernetics by Maxwell Maltz</p>
      </div>
      <div class="content column has-text-left">
        <h4>Music genres</h4>
        <p>English pop, K-pop, Lo-fi, R&amp;B, Jazz, Disney </p>
      </div>
    </div>
  </div>
</template>
