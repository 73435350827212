<template>
  <div class="section mb-5" style="margin-top: 70px">
    <div class="columns is-centered is-multiline">
      <div class="column is-11">
        <router-link :to="{ name: 'Karoki' }">
          <div
            class="columns is-vcentered card has-background-white custom-card"
            style="padding: 20px 10px"
          >
            <div class="column is-4 px-4">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    src="../assets/thumbnails/t1.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
            </div>
            <div class="column is-8 px-4 center">
              <div class="content has-text-left">
                <p class="title">Karoki</p>
                <p class="subtitle">
                  Designing a desktop application that best simulates the
                  physical karaoke experience
                </p>
                <div class="tags">
                  <span class="tag is-primary is-light">UX Research</span>
                  <span class="tag is-link is-light">UI Design</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="column is-11 mt-5">
        <router-link :to="{ name: 'Collactive' }">
          <div
            class="columns is-vcentered card has-background-white custom-card"
            style="padding: 20px 10px"
          >
            <div class="column is-4 px-4">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    src="../assets/thumbnails/t2.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
            </div>
            <div class="column is-8 px-4 center">
              <div class="content has-text-left">
                <p class="title">Collactive</p>
                <p class="subtitle">
                  Designing a platform that supplies donated, extra breast milk
                  to other mothers in need
                </p>
                <div class="tags">
                  <span class="tag is-link is-light">UI Design</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>