<template>
  <div class="section center" style="margin-top: 70px; min-height: 70vh">
    <div
      class="column card is-4 resume-container"
      style="width: 420px; height: 594px"
    >
      <a href="/Ho_Chinn_Fang_Resume.pdf" download="Ho Chinn Fang Resume">
        <div class="button is-link" id="resume-download-btn">
          Download my resume
        </div>
      </a>
      <div class="resume"></div>
      <img src="../assets/resume-blurred.png" id="resume" />
    </div>
  </div>
</template>

<style>
.resume-container {
  position: relative;
  text-align: center;
  color: white;
}

#resume-download-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>