<template>
  <section class="section is-paddingless-horizontal" style="margin-top: 70px">
    <div class="container">
      <div class="block center">
        <div class="tags">
          <span class="tag is-info is-light">Java EE</span>
          <span class="tag is-info is-light">JSF</span>
          <span class="tag is-info is-light">Angular</span>
        </div>
      </div>

      <div
        class="section is-paddingless-horizontal"
        style="padding-top: 0px; padding-bottom: 10px"
      >
        <h1 class="title is-2">Subscription Marketplace</h1>
        <h2 class="subtitle is-5 mt-1">
          Creating a subscription marketplace application where customers can
          subscribe to products or services
        </h2>
      </div>
    </div>
    <div class="section">
      <figure class="image card is-mobile is-marginless">
        <img src="../assets/banners/a4.png" alt="" />
      </figure>
    </div>
    <div class="container grid">
      <div class="content has-text-left">
        <h1>At a glance</h1>
        <p>
          As part of a school module, CS2102 Database Systems, my team was
          tasked to code a Food Delivery Service web application that used a
          Postgres database.
        </p>
        <br />
        <table>
          <tbody>
            <tr>
              <td><strong>Role</strong></td>
              <td>Developer</td>
            </tr>
            <tr>
              <td><strong>Date</strong></td>
              <td>Jan - May 2020</td>
            </tr>
            <tr>
              <td><strong>Project Type</strong></td>
              <td>School Coding Project</td>
            </tr>
            <tr>
              <td><strong>Tech</strong></td>
              <td>
                Java EE<br />
                JSF<br />
                Angular
              </td>
            </tr>
          </tbody>
        </table>
        <h1>My responsibilities</h1>
        <ul>
          <li>Design the home page</li>
          <li>Develop dashboard view</li>
          <li>
            Develop the product viewing page, for customers to view available
            products to subscribe to
          </li>
          <li>Develop functionality to rate and review a product</li>
          <li>
            Develop functionality for users to view and update their user
            profile
          </li>
          <li>
            Develop functionality for merchants to view and update their
            merchant profile
          </li>
        </ul>
        <br />
        <br />
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Home page</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/sm/s1.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Home page</small>
      </figcaption>
      <br />
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Dashboard</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/sm/s2.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Dashboard</small>
      </figcaption>
      <br />
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Browse Products</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/sm/s3.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Browse products</small>
      </figcaption>
      <br />
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Rate and Review Products</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/sm/s4.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Rate and review products</small
        >
      </figcaption>
      <br />
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>View and Edit Profile</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/sm/s5.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> View and edit profile</small>
      </figcaption>
      <br />
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>View and Edit Profile for Merchants</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/sm/s6.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          View and edit profile for merchants</small
        >
      </figcaption>
      <br />
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Demo video</h2>
        <br />
      </div>
    </div>

    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/Sic65Wea2fA"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </section>
</template>

<script>
  export default {};
</script>

<style>
  .is-paddingless-horizontal {
    padding-left: 0;
    padding-right: 0;
  }

  .grid {
    display: grid;
    grid-template-columns: [xl-start] 1fr 1.5rem [md-start] minmax(0, 624px) [md-end] 1.5rem 1fr [xl-end];
  }

  .grid * {
    grid-column: md;
  }

  .grid-xl * {
    grid-column: xl;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 672px) {
    :root {
      font-size: 90%;
    }
  }
</style>
