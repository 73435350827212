<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        Handmade with &#129293;
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
.remove-link-colour {
  color: #2c3e50 !important;
}
.remove-link-colour:hover {
  color: #485fc7 !important;
}
</style>