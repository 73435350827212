import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Design from "../views/Design.vue";
import Tech from "../views/Tech.vue";
import Karoki from "../views/Karoki.vue";
import Collactive from "../views/Collactive.vue";
import FDS from "../views/FDS.vue";
import SM from "../views/SM.vue";
import Marquee from "../views/Marquee.vue";
import Resume from "../views/Resume.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/design",
    name: "Design",
    component: Design,
  },
  {
    path: "/design/karoki",
    name: "Karoki",
    component: Karoki,
    metaInfo: {
      title: "Karoki",
    },
  },
  {
    path: "/design/collactive",
    name: "Collactive",
    component: Collactive,
  },
  {
    path: "/tech",
    name: "Tech",
    component: Tech,
  },
  {
    path: "/tech/food-delivery-service",
    name: "FDS",
    component: FDS,
  },
  {
    path: "/tech/subscription-marketplace",
    name: "SM",
    component: SM,
  },
  {
    path: "/tech/marquee",
    name: "Marquee",
    component: Marquee,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/resume",
    name: "Resume",
    component: Resume,
  },
  // catchall 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
