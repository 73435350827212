<template>
  <section class="section is-paddingless-horizontal" style="margin-top: 70px">
    <div class="container">
      <div class="block center">
        <div class="tags">
          <span class="tag is-link is-light">UI Design</span>
        </div>
      </div>

      <div
        class="section is-paddingless-horizontal"
        style="padding-top: 0px; padding-bottom: 10px"
      >
        <h1 class="title is-2">Collactive</h1>
        <h2 class="subtitle is-5">
          Designing a platform that supplies donated, extra breast milk to other
          mothers in need
        </h2>
      </div>
    </div>
    <div class="section">
      <figure class="image card is-mobile is-marginless">
        <img src="../assets/banners/a2.png" alt="" />
      </figure>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h1>At a glance</h1>
        <p>
          As part of my NUS Overseas Colleges experience (more on that here), my
          team was required to pitch for a startup idea. Our idea was to create
          a platform that supplies mothers who need breast milk using donated
          breast milk from other mothers.
        </p>
        <p>
          We were a team of four, and I was the sole product designer. After
          deciding on the general product features as a team, I went on to
          design the high-fidelity prototype in Figma for the mobile version of
          the application.
        </p>
        <br />
        <table>
          <tbody>
            <tr>
              <td><strong>Role</strong></td>
              <td>Sole Product Designer</td>
            </tr>
            <tr>
              <td><strong>Date</strong></td>
              <td>Oct - Dec 2020</td>
            </tr>
            <tr>
              <td><strong>Project Type</strong></td>
              <td>Startup Ideation and Pitching</td>
            </tr>
            <tr>
              <td><strong>Design Tools</strong></td>
              <td>Figma</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>Colour Theme</h1>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/collactive/c1.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Colour theme </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          Here is the colour theme I chose for the application. Pinks and softer
          greys are used, to bring on the maternity and feminine nature of this
          application.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>Initial Logo Design</h1>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/collactive/c2.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Initial logo design </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          This is the first logo that I designed for the brand. The black curve
          is in the shape of a letter ‘C’ to represent Collactive, which is the
          name of our platform. Its second meaning is to resemble a breast. The
          pink droplet represents breast milk.
        </p>
        <p>
          However, my teammates found this logo unintuitive. With their valuable
          feedback, I revised the logo to make it more clear about what our
          brand is about.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>Revised Logo Design</h1>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-6">
          <figure class="image">
            <img src="../assets/collactive/c3.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Revised logo design </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          Here is the revised logo design. The relation to breast milk is made
          clearer through the image of a baby suckling on a breast. Aside from
          updating the visuals, I also updated the font used. This font,
          Manrope, seems much more modern, friendly and gentle than the previous
          font used.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>Low-fidelity Prototype</h1>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/collactive/c4.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Low-fidelity prototypes
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          I drafted low-fidelity prototypes before turning them into high
          fidelity. This allowed me to work faster, as I did not have to focus
          on the aesthetics of the design. Rather, I could focus on working on
          the functional aspects of the design, making sure that what was
          necessary was properly included.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>High-fidelity Prototype</h1>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-6">
          <figure class="image">
            <img src="../assets/collactive/c5.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Completed high-fidelity prototype
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          Here are the completed high-fidelity prototypes. Let me walk you
          through the key sections of the app.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>App start-up</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/collactive/c6.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> App start-up </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          On initial app start-up, the user will first get to see a quick
          introduction of what the app is about. The illustrations here are
          mostly from the Figma plugin, Storyset by Freepik.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Home screen</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-3">
          <figure class="image">
            <img src="../assets/collactive/c7.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Home screen </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          On the home screen, the two main call to action buttons – Order and
          Donate – are emphasized. At a glance, users can quickly see how many
          points they have left, their current subscription, and their recent
          history.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Making an order</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/collactive/c8.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Making an order </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          Mothers in need of breast milk can make an order for it. It is a short
          process of indicating their preferences. A progress bar at the top of
          the page lets the user manage their expectations about how long the
          process will take.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Making a donation</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/collactive/c9.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Making a donation </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          Mothers with excess breast milk can also use the platform to donate
          excess milk. The user interface is similar to the previous feature of
          ordering milk. Lastly, an integration with SingPass will be used to
          streamline the process of keying in personal particulars.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Other features</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/collactive/c10.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Articles, forum and specialist Q&#38;A
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          Aside from ordering and donating breast milk, other features such as
          informative articles, a forum, and specialist Q&#38;A will be
          available to users.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>Reflections</h1>
        <p>
          It was a joy to work on this project! It was my first time creating a
          brand logo, and the first time I had autonomy to decide on the
          branding of something new. The freedom and autonomy was refreshing and
          empowering.
        </p>
        <p>
          I am glad to have the close teamwork and support from my teammates.
          They were supportive of my design works, and actively gave feedback to
          me. The partnership with them was invaluable in realising the
          prototype for our idea.
        </p>
        <p>
          What was more challenging would be the coordination of features with
          the business teammates. These teammates were in charge of interviewing
          breast milk donees. Through the interview process, they got a better
          idea of what needed to go into the mobile app. It was important that
          we had regular sync-ups to catch up on which aspects of the features
          would be updated.
        </p>
        <h1>An update on the project</h1>
        <p>
          Our team was successful with the VIP pitch, and Collactive was awarded
          with a $10k grant to kickstart the idea. However, two out of four of
          us, including myself, withdrew from the team. The last remaining two
          teammates will continue developing the idea.
        </p>
        <p>
          I chose to withdraw because I was not overly passionate about this
          idea. Although this idea originated from me, it was not a cause that I
          felt particularly strong for. I was not ready to commit to it under
          such circumstances. However, I do wish my teammates all the best! In
          the meantime, I shall be in search of other ideas to develop. x
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.is-paddingless-horizontal {
  padding-left: 0;
  padding-right: 0;
}

.grid {
  display: grid;
  grid-template-columns: [xl-start] 1fr 1.5rem [md-start] minmax(0, 624px) [md-end] 1.5rem 1fr [xl-end];
}

.grid * {
  grid-column: md;
}

.grid-xl * {
  grid-column: xl;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 672px) {
  :root {
    font-size: 90%;
  }
}
</style>