<template>
  <div class="home" style="margin-bottom: 150px;">

    <div class="section columns is-vcentered is-multiline is-mobile center" style="padding: 120px 0; margin: 100px 0;">
      <div class="column is-full m-6">
        <figure class="image">
          <img alt="hello! it's chinn fang." src="../assets/chinn_home.png" />
        </figure>
      </div>
    </div>
    <div class="section columns is-centered is-multiline mx-5" style="margin: 100px 0;">
      <div class="column is-6">
        <figure class="image">
          <img alt="venn diagram" src="../assets/venn.png" />
        </figure>
      </div>
      <div class="column is-8">
        <div class="content">
          <p class="is-size-4">
            This is me in a nutshell &#128524; 
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home"
};
</script>

<style>
</style>
