<template>
  <section class="section is-paddingless-horizontal" style="margin-top: 70px">
    <div class="container">
      <div class="block center">
        <div class="tags">
          <span class="tag is-info is-light">VueJS</span>
          <span class="tag is-info is-light">PostgreSQL</span>
        </div>
      </div>

      <div
        class="section is-paddingless-horizontal"
        style="padding-top: 0px; padding-bottom: 10px"
      >
        <h1 class="title is-2">Food Delivery Service</h1>
        <h2 class="subtitle is-5 mt-1">
          Developing a simple food delivery service application for customers,
          managers and restaurant staff
        </h2>
      </div>
    </div>
    <div class="section">
      <figure class="image card is-mobile is-marginless">
        <img src="../assets/banners/a3.png" alt="" />
      </figure>
    </div>
    <div class="container grid">
      <div class="content has-text-left">
        <h1>At a glance</h1>
        <p>
          As part of a school module, CS2102 Database Systems, my team was
          tasked to code a Food Delivery Service web application that used a
          Postgres database.
        </p>
        <br />
        <table>
          <tbody>
            <tr>
              <td><strong>Role</strong></td>
              <td>Developer</td>
            </tr>
            <tr>
              <td><strong>Date</strong></td>
              <td>Jan - May 2020</td>
            </tr>
            <tr>
              <td><strong>Project Type</strong></td>
              <td>School Coding Project</td>
            </tr>
            <tr>
              <td><strong>Tech</strong></td>
              <td>
                PostgreSQL<br />
                VueJS<br />
                NodeJS
              </td>
            </tr>
          </tbody>
        </table>
        <h1>My responsibilities</h1>
        <ul>
          <li>Design the home page</li>
          <li>Develop dashboard view for managers</li>
          <li>Develop the menu view for customers</li>
          <li>Develop the food item management view for restaurant staff</li>
          <li>Develop promotional campaign management for restaurant staff</li>
        </ul>
        <br />
        <br />
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h2>Home page</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/fds/f1.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Home page</small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Dashboard for Manager</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/fds/f2.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Dashboard for Manager</small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Browsing of menu for Customers</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/fds/f3.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Browsing of menu for Customers</small
        >
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Food item management for Restaurant Staff</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/fds/f4.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Food item management for Restaurant Staff</small
        >
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Promotional campaign management for Restaurant Staff</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/fds/f5.png" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Promotional campaign management for Restaurant Staff</small
        >
      </figcaption>
    </div>
  </section>
</template>

<script>
  export default {};
</script>

<style>
  .is-paddingless-horizontal {
    padding-left: 0;
    padding-right: 0;
  }

  .grid {
    display: grid;
    grid-template-columns: [xl-start] 1fr 1.5rem [md-start] minmax(0, 624px) [md-end] 1.5rem 1fr [xl-end];
  }

  .grid * {
    grid-column: md;
  }

  .grid-xl * {
    grid-column: xl;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 672px) {
    :root {
      font-size: 90%;
    }
  }
</style>
