<template>
  <section
    class="section is-paddingless-horizontal"
    style="margin-top: 70px"
    id="karoki-top"
  >
    <div class="container">
      <div class="block center">
        <div class="tags">
          <span class="tag is-primary is-light">UX Research</span>
          <span class="tag is-link is-light">UI Design</span>
        </div>
      </div>

      <div
        class="section is-paddingless-horizontal"
        style="padding-top: 0px; padding-bottom: 10px"
      >
        <h1 class="title is-2">Karoki</h1>
        <h2 class="subtitle is-5">
          Designing a desktop application that best simulates the physical
          karaoke experience
        </h2>
      </div>
    </div>
    <div class="section">
      <figure class="image card is-mobile is-marginless">
        <img src="../assets/banners/a1.png" alt="" />
      </figure>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h1>At a glance</h1>
        <p>
          As part of an Interaction Design module, I was tasked to create an
          application that would best simulate the physical karaoke experience.
          This was a solo project where I was the only designer in charge of
          everything - from UX research, to lo-fi and hi-fi prototyping.
        </p>
        <br />
        <table>
          <tbody>
            <tr>
              <td><strong>Role</strong></td>
              <td>
                Discovery and research<br />
                Ideation<br />
                Low-fidelity prototyping<br />
                High-fidelity prototyping
              </td>
            </tr>
            <tr>
              <td><strong>Date</strong></td>
              <td>Mar 2021</td>
            </tr>
            <tr>
              <td><strong>Project Type</strong></td>
              <td>Solo Project</td>
            </tr>
            <tr>
              <td><strong>Design Tools</strong></td>
              <td>
                Balsamiq<br />
                Figma
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <p>
          <span class="title is-size-3">Since COVID started,</span> many of us
          have said a temporary goodbye to what used to be an ever-popular
          pastime -- karaoke. With many physical karaoke television (KTV)
          outlets closed indefinitely, it is an activity that has been dearly
          missed by everyone, myself included!
        </p>
      </div>
    </div>

    <div class="section">
      <div class="columns center is-marginless">
        <div class="column is-5">
          <figure class="image">
            <img src="../assets/karoki/k1.svg" alt="" />
          </figure>
        </div>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h1>It’s just not the same as going to Teo Heng</h1>
        <p>
          Looking on the internet for some virtual alternatives, I came across a
          few social karaoke mobile applications such as Smule and 全民party.
          However, their public communities and competitive nature were not to
          my liking. I wanted a more private experience more similar to a cosy
          hangout with friends and family.
        </p>
      </div>
    </div>

    <div class="section">
      <div class="columns center is-marginless">
        <div class="column is-8">
          <figure class="image">
            <img src="../assets/karoki/k2.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Screenshots of Smule and 全民party. Visibly, they have a strong focus
          on community and are of a slightly competitive nature. You may duet
          others publicly, or award gifts to people who sing well.
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h1>Other notable observations about Smule and 全民party</h1>
        <ul>
          <li>Starting a private room with others is not intuitive</li>
          <li>
            Information overload: too much going on, feels very overwhelming for
            the user
          </li>
          <li>
            No music videos shown during karaoke, although they are a key
            element of physical karaoke sessions
          </li>
        </ul>
        <h1>Meet your new KTV, Karoki</h1>
        <p>
          Unsatisfied with what I found, I decided to solve this problem by
          designing Karoki, a new application that would more closely replicate
          the physical karaoke experience that we are more familiar with.
        </p>
        <br />
        <p>In taking on this design challenge, I have two main motives:</p>
        <ol>
          <li>Replicate the KTV experience we miss onto a virtual platform</li>
          <li>
            Practice designing by undergoing a full design process, from user
            research and ideation to prototyping and user testing
          </li>
        </ol>
        <br />
        <p>Initial visions for Karoki <i>(stretch goals!</i>):</p>
        <ol>
          <li>
            <strong>Less public, more private:</strong> Rather than being a type
            of social media, think personal Zoom calls with your favourite
            people or a Netflix Party.
          </li>
          <li>
            <strong>Your favourite parts of KTV, back again:</strong> Think
            music videos with rolling lyrics. See everyone all hyped up. Cheer
            your friends on. What a night! And of course, dark mode.
          </li>
          <li>
            <strong> and seamless, just like joining a Zoom call:</strong> No
            unnecessary steps, no friction along the way. So easy that you
            forget you’re using an online platform.
          </li>
        </ol>
        <h1>What makes physical karaoke, physical karaoke?</h1>
        <p>
          To better understand the experience that I was trying to recreate, I
          started by interviewing my friend Minru. The interview was centred
          around a few key questions: What motivates you to karaoke? What makes
          a good karaoke session? What are your karaoke sessions usually like?
        </p>
      </div>
    </div>

    <div class="section">
      <div class="columns center is-marginless">
        <div class="column is-8">
          <figure class="image">
            <img src="../assets/karoki/k3.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Notes from the interview with Minru
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <p>
          With a better picture of the elements of physical karaoke, I wanted to
          verify this with more people. To do so, I conducted an online survey
          titled “How do you karaoke?”, with the aims of confirming what people
          valued or prioritised in a physical karaoke session. The survey was
          sent out to peers who were patrons of physical KTV pre-COVID.
        </p>
        <p>
          The 22 respondents ranged from 21 to 25 years of age, with 45.5% being
          male and 54.5% being female.
        </p>
      </div>
    </div>

    <div class="section">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k4.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Results of the survey </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <p>Summary of findings:</p>
        <ul>
          <li>
            <strong>Karaoke is a social activity.</strong> 0% of respondents
            karaoke alone and the majority of people usually karaoke with
            friends.
          </li>
          <li>
            <strong>The people singing in the room matter.</strong> Who they are
            and how hyped they are contributes to how great the session is!
          </li>
          <li>
            <strong>Important to have visibility of people in action.</strong>
            Visibly being able to see people singing, swaying or dancing is
            important in creating the ambience for karaoke.
          </li>
          <li>
            <strong>Music matters.</strong> Being able to sing the songs that
            you like. The type of music genre playing.
          </li>
          <li>
            <strong>Top difficulties</strong> are in choosing songs due to
            everyone’s different preferences for songs/genre and not knowing the
            songs that other people choose.
          </li>
        </ul>

        <h1>Setting ourselves up for success</h1>
        <p>
          Before we proceed any further, it is important that we have a clear
          picture of what we are trying to solve and what our goals are in this
          process.
        </p>
        <br />
        <h4>Problem Statement for Karoki</h4>
        <ul>
          <li>
            Help people relive the joy and social interactions of a physical
            karaoke virtually.
          </li>
        </ul>
        <br />
        <h4>Design considerations</h4>
        <ol>
          <li>
            Do not clutter the interface so as to keep the main focus on people,
            the music video and lyrics.
          </li>
          <li>
            Dark-themed UI so that people can replicate the karaoke experience
            in the dark.
          </li>
        </ol>
        <br />
        <h4>Constraints</h4>
        <ul>
          <li>
            Current technologies do not allow for two or more people to sing
            together without any audio delay, so we will have to make use of
            other ideas to best simulate the experience of singing together.
          </li>
        </ul>
        <br />
        <h4>Design goals</h4>
        <ul>
          <li>
            How might we replicate the elements of a physical KTV virtually?
          </li>
          <li>
            How might we enhance the physical KTV experience on a virtual
            platform?
          </li>
          <li>
            How might we create virtual interactions between participants in a
            way that is similar to real physical interactions at a KTV?
          </li>
          <li>How might we introduce fun and delight to the experience?</li>
          <li>
            How might we help participants learn more about each other’s music
            tastes?
          </li>
        </ul>
        <h1>Understanding our users</h1>
        <p>
          To keep the needs of users in mind as I design the solution, I made
          use of a user persona and empathy map.
        </p>
      </div>
    </div>

    <div class="section">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k5.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> User persona </small>
      </figcaption>
      <br />
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k6.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Empathy map </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h1>Ideation</h1>
        <p>
          With a clear idea of the user we are designing for, I proceeded to
          brainstorm for ideas according to the design goals for this project,
          which are the five how-might-we statements.
        </p>
      </div>
    </div>

    <div class="section">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k7.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Brainstorming using HMW statements before shortlisting ideas to use
          for Karoki
        </small>
      </figcaption>
      <br />
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k8.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Finding affinity with shortlisted ideas
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <h1>Crazy 8s</h1>
        <p>
          As we kickstart the prototyping process, I listed down my initial
          thoughts of the features for Karoki:
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left is-small">
        <br />
        <table>
          <thead>
            <tr>
              <th>Feature</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Home Screen</td>
              <td>
                Option to start a karaoke room<br />
                View recent songs / karaoke sessions
              </td>
            </tr>
            <tr>
              <td>User Profile</td>
              <td>
                Show music genre that they like <br />
                Sync up with their Spotify<br />
                Listing of their favourite KTV songs <br />
                Show recent KTV songs<br />
                See past history on Karoki
              </td>
            </tr>
            <tr>
              <td>Karaoke Room Setup</td>
              <td>
                Set room title<br />
                Set room description<br />
                Invite other users on Karoki, or copy a room invite link<br />
                Set a passcode<br />
                Set host rights for the session
              </td>
            </tr>
            <tr>
              <td>Entering Karaoke Room</td>
              <td>
                Set display name<br />
                Set virtual background <br />
                Set video filter (party hats, props)<br />
                Advice participants to turn off lights, get ready food etc.
              </td>
            </tr>
            <tr>
              <td>Active Karaoke Room</td>
              <td>
                Music video<br />
                Everyone's faces<br />
                Selection of songs <br />
                Show preference for songs in queue<br />
                Overview of room<br />
                Adjusting of karaoke room settings<br />
                Nominate a person to sing/pass the mic to someone<br />
                Randomiser to pick singer<br />
                Show reactions to others’ singing<br />
                Main chat room and private messaging<br />
                Leave room<br />
                End room
              </td>
            </tr>
            <tr>
              <td>Closing of Karaoke Room</td>
              <td>
                Phototaking session<br />
                View statistics<br />
                Option to share on social media
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          With that as a guide, I divided into Crazy 8s, where I drew different
          variations for each feature.
        </p>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k9.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Completed Crazy 8 ideation process
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          As part of an iterative approach, I asked my brother for feedback on
          the sketches I have come up with. Both my personal comments and his
          are recorded on sticky notes, as seen below.
        </p>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k10.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Got feedback from Jing Xian
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>Low-fidelity Prototyping</h1>
        <p>
          With the comments in mind, I proceeded on to do lofi-prototyping using
          Balsamiq.
        </p>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k11.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Lofi mockups for Karoki
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          Let me walk you through some of the key design decisions that were
          made.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Home Screen</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k12.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Home screen </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          For the home screen, I chose to welcome the user with a personalised
          greeting using their name and display image. This choice was made
          specifically to foster feelings of friendliness and welcome.
        </p>
        <p>
          The main call to action – to start a karaoke room – will be a primary
          button, while joining a room will be the secondary button.
        </p>
        <p>
          Finally, other administrative and miscellaneous controls are small and
          located at the corner of the screen. This is because they are tertiary
          items that do not need to be emphasized nor frequently accessed by the
          user.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>User Profile</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-6">
          <figure class="image">
            <img src="../assets/karoki/k13.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> User profile </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          This is the user profile that other users will get to see. It was
          designed to help communicate the user’s music preferences to others,
          so that song choices and music tastes can be better coordinated in a
          karaoke session.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Karaoke Room Setup and Joining</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k14.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Start or join a karaoke room
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          For “Start a Room”: The “Copy” button helps hosts to quickly copy and
          share the karaoke room invite link.
        </p>
        <p>
          For “Join Room”: At the top left hand corner, there is a button to
          “Start a room instead” just in case the host is facing technical
          issues. In that case, any user can start a new room on his behalf.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Entering of Karaoke Room</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-6">
          <figure class="image">
            <img src="../assets/karoki/k15.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"
          >Choosing of outfit before you enter the karaoke room
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          Before entering the karaoke room, participants will get to choose
          their virtual outfits and virtual background. Here, the prompting
          question “What’s your outfit for today?” injects some fun and reminds
          users to let loose.
        </p>
        <p>
          The confirmation button, located at the top right hand corner, also
          uses positive wording of “I’m ready!” to set a good mood for the
          session.
        </p>
        <br />
      </div>

      <div class="container grid">
        <div class="content has-text-left">
          <h2>Karaoke Room</h2>
        </div>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-8">
          <figure class="image">
            <img src="../assets/karoki/k16.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Karaoke room </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          The karaoke room was designed to fit in 6 faces. This is based on the
          feedback that seeing the reactions of people was an important element
          of karaoke.
        </p>
        <p>
          Controls for the karaoke session will hover on the top and the right
          side, so that it does not block the main focus of users’ faces and the
          karaoke screen.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Selection of Songs</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-6">
          <figure class="image">
            <img src="../assets/karoki/k17.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Selection of songs </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          When the song selection menu is open, some participants’ faces will
          still be visible. Being able to continue seeing what is going on in
          the room is important, so that users who are choosing songs can still
          feel in touch with what’s going on.
        </p>
        <p>
          In the song queue, song positions can be easily rearranged through
          dragging. This is communicated to the user through the three-lined
          icon, which signals that manual reordering is possible.
        </p>
        <p>
          At the top of the song queue, there is a search bar to support quick
          adding of songs, when users already know what they want to sing.
          Otherwise, they can choose from the song book, located beside the
          search bar.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Searching for Songs</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-6">
          <figure class="image">
            <img src="../assets/karoki/k18.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Searching for songs </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          To increase the speed and ease of searching, the search bar will have
          autofill functionality. Search results will include relevant
          recommendations to facilitate song exploration and the adding of songs
          to the song queue.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Chat</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k19.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Main chat room and private chats
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          The main room chat and private chats are separated, as many people
          have a fear of accidentally replying to the wrong chat (replying to
          everyone instead of just a single person). Furthermore, it can be hard
          to keep up with conversations when they are all mixed together in a
          single layout (like in Zoom). By separating main room chat and private
          chats, I hope to create a better chat experience for participants,
          enabling them to interact confidently and comfortably with each other.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Room Overview</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-7">
          <figure class="image">
            <img src="../assets/karoki/k20.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Room overview </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          For the room overview, I chose to use tabs to organise the different
          pieces of information, rather than overwhelming the user all at one
          go. Regardless of which tab the user is on, they will still be able to
          see the room name and the members within the room.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Room Settings</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-9">
          <figure class="image">
            <img src="../assets/karoki/k21.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Room settings </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          When the host clicks on “Close Room”, a confirmation message will be
          displayed. This is to prevent user errors, in case it was clicked on
          by accident.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>End of Karaoke</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-6">
          <figure class="image">
            <img src="../assets/karoki/k22.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> End of karaoke </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          At the end of the karaoke, users will get to see room statistics, such
          as the number of songs that were sung and the number of genres
          explored. This helps them to look back at the great session that they
          had and fondly end the session. The group can also choose to take a
          photo to commemorate the session.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Photo Taking</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-6">
          <figure class="image">
            <img src="../assets/karoki/k23.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Photo taking </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          Users will be able to customise their choice of photo frames and props
          before taking the photo. This can be very fun and interactive for the
          group. There will be a timer, and everyone can see the actual template
          up in fullscreen to prepare for the shot. After the photo is taken,
          users can save the photo or take another one until they are satisfied.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>High-fidelity Prototyping</h1>
        <p>
          The high-fidelity prototypes are an iteration from the low-fidelity
          wireframes. Here, I improved on the layout and compositions as I
          started to get a clearer picture of what the end product would look
          like.
        </p>
        <br />
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k24.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Hi-fi mockups for Karaoki
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br /><br />
        <p>
          Before revealing the final prototype, let me walk you through the key
          changes that were made.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Key Change 1: Karaoke room menu placement</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-7">
          <figure class="image">
            <img src="../assets/karoki/k25.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Change in karaoke room menu placement
        </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          In the lo-fi mockups, the menu was placed on the top and the right. I
          had received feedback that the control panel on the right was hard to
          navigate due to its irregular position of buttons (if you recall, the
          buttons were aligned vertically, at the side of the right panel).
          Hence, I have changed the right menu to be located at the bottom
          instead. This has a couple of benefits. First, the overall design is
          now more balanced. Second, we now have more screen space. Third, the
          buttons can be arranged horizontally, which is a manner that is more
          familiar to users.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Key Change 2: Song recommendations for quick add to song queue</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-6">
          <figure class="image">
            <img src="../assets/karoki/k26.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Song recommendations beside the song queue</small
        >
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          Aligning the menu to the bottom of the screen gave us much more screen
          space. With that additional space, I was able to introduce song
          suggestions at the side of the song queue. Users can quickly add them
          to the queue by simply clicking on the Add button beside each song
          suggestion.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h2>Key Change 3: Room overview design</h2>
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k27.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Room overview</small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <p>
          For the Room Overview, the tab organisation was moved to the right for
          a more clean and minimal design. This also helps to position the tabs
          further away from the main menu controls (located at the top), so that
          it will not be confusing for users.
        </p>
      </div>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>Final Screens</h1>
        <p>Here are the final hi-fi screens.</p>
        <br />
      </div>
    </div>

    <div class="section py-0">
      <div class="columns center is-marginless">
        <div class="column is-7">
          <figure class="image">
            <img src="../assets/karoki/k28.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Home Screen, User Profile
        </small>
      </figcaption>
      <br />
      <div class="columns center is-marginless">
        <div class="column is-11">
          <figure class="image">
            <img src="../assets/karoki/k29.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Start Room, Join Room </small>
      </figcaption>
      <br />
      <div class="columns center is-marginless">
        <div class="column is-10">
          <figure class="image">
            <img src="../assets/karoki/k30.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Select your outfit before entering the room
        </small>
      </figcaption>
      <br />
      <div class="columns center is-marginless">
        <div class="column is-8">
          <figure class="image">
            <img src="../assets/karoki/k31.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Main karaoke room, hover at the top and bottom for controls
        </small>
      </figcaption>
      <br />
      <div class="columns center is-marginless">
        <div class="column is-11">
          <figure class="image">
            <img src="../assets/karoki/k32.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Song selection </small>
      </figcaption>
      <br />
      <div class="columns center is-marginless">
        <div class="column is-6">
          <figure class="image">
            <img src="../assets/karoki/k33.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Main room chat and private chat
        </small>
      </figcaption>
      <br />
      <div class="columns center is-marginless">
        <div class="column is-12">
          <figure class="image">
            <img src="../assets/karoki/k34.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Room overview </small>
      </figcaption>
      <br />
      <div class="columns center is-marginless">
        <div class="column is-7">
          <figure class="image">
            <img src="../assets/karoki/k35.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey">
          Closing of karaoke room, with a dialogue box overlay for action
          confirmation
        </small>
      </figcaption>
      <br />
      <div class="columns center is-marginless">
        <div class="column is-12">
          <figure class="image">
            <img src="../assets/karoki/k36.svg" alt="" />
          </figure>
        </div>
      </div>
      <figcaption class="level column is-9" style="margin: 0 auto">
        <small class="level-item has-text-grey"> Taking of group photo </small>
      </figcaption>
    </div>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>Interactive Prototype</h1>
        <p>Please full-screen to test.</p>
        <br />
      </div>
    </div>

    <iframe
      style="border: 1px solid rgba(0, 0, 0, 0.1)"
      width="800"
      height="450"
      src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FvJ30qDYjOg6RE7m7V7QISt%2FKaroki-High-fidelity-Prototype%3Fpage-id%3D38%253A2969%26node-id%3D38%253A2972%26viewport%3D251%252C48%252C0.03%26scaling%3Dmin-zoom%26starting-point-node-id%3D38%253A2972%26show-proto-sidebar%3D1"
      allowfullscreen
    ></iframe>

    <div class="container grid">
      <div class="content has-text-left">
        <br />
        <h1>Reflections</h1>
        <p>
          As this was my first personal design project, everything that I’ve
          done here felt very new and refreshing to me. It was not an easy
          process; extremely arduous, if I were to be honest. Yet, it was
          extremely insightful.
        </p>
        <h3>1. Good to learn from others</h3>
        <p>
          I had not done a design project before, but I wanted to do it well and
          properly. I made sure to research popular UX case studies in the
          industry, and took reference from what I liked from each portfolio.
          This process was very inspiring for me – I was amazed at the wonderful
          works that seemed so perfect and well thought through! So much effort
          must have gone into those works, and I am thankful to have them as my
          guides.
        </p>
        <h3>2. Struggles with documentation</h3>
        <p>
          After completing this design project, I realised that so much work
          goes behind the scenes of a good case study. Not only was the process
          itself important (steps in the design process, the analysis and
          iterations along the way), the documentation was massive too. With so
          many insights and iterations that came out from the design process, I
          struggled with backtracking my work to put together screenshots or
          artefacts that depicted my process. Learning from this experience, I
          will work to improve my documentation process in future.
        </p>
        <h3>3. Need to improve time estimation and management</h3>
        <p>
          I did not expect the whole process to take so long; I underestimated
          the time needed to finish this project. As I was initially very
          excited about doing a design project on my own, I put in a lot of
          effort into information gathering, understanding users and looking at
          how other people in the industry typically undergo a design or UX
          process. However, the subsequent phases of the design process are
          extremely time consuming as well, high-fidelity prototyping in
          particular. To tell the truth, I did run out of time for this design
          project – I dedicated a week to it during school, but unfortunately, I
          did not have enough time to conduct more user testing or iterate
          further on my designs. Taking note of this, I hope to have a more
          realistic timeline next time. I could also set priorities or manage my
          expectations for future projects, to make sure that the work will be
          manageable for that timeframe.
        </p>
        <h3>4. Leverage design tools to speed up the process</h3>
        <p>
          AutoLayout in Figma has helped me so much with the designing of this
          project, saving me much time on aligning elements. Prior to this
          project, I have always wanted to learn AutoLayouts, but I did not get
          around to doing it. I realised that it is important for designers to
          continuously stay updated with the latest tools and functionalities
          they can use in the design world, to make the design process much more
          efficient. Having undergone this arduous and lengthy process myself, I
          understand why it is so important to have design systems and processes
          in place. In the real world where product, design and engineering has
          to work together, these processes will definitely help in reducing the
          time taken for the design team to get up to speed with the latest
          product developments, in order to pass them over to the engineering
          team in time.
        </p>
        <p>
          I have invested a considerable amount of time in sourcing and
          establishing a design system (mostly using the community resources on
          Figma!), and I really believe that it is a worthy time investment. I
          am very thankful to have gained experience with working with design
          systems, although I still need to make better decisions on which
          elements should become Master Components.
        </p>
        <h1>Moving Forward</h1>
        <p>
          There are a number of things that can be further improved for this
          project.
        </p>
        <p>
          First, more user testing can be done to iterate on the design and
          experience of Karoki.
        </p>
        <p>
          Second, adding a user walkthrough at the beginning can help new users
          to learn how to use the application.
        </p>
        <p>
          Third, I will need to think about how the karaoke room will look like
          with a variable number of room participants. Currently, we have 6
          karaoke room participants for this prototype. The karaoke room is
          currently designed to show 3 participants each on the left and right
          side of the screen. But what if we have less or more than that? Would
          another layout be more optimal? This is something that I will have to
          think about.
        </p>
        <br />
      </div>
    </div>
  </section>
</template>

<script>
export default {
};
</script>

<style>
.is-paddingless-horizontal {
  padding-left: 0;
  padding-right: 0;
}

.grid {
  display: grid;
  grid-template-columns: [xl-start] 1fr 1.5rem [md-start] minmax(0, 624px) [md-end] 1.5rem 1fr [xl-end];
}

.grid * {
  grid-column: md;
}

.grid-xl * {
  grid-column: xl;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 672px) {
  :root {
    font-size: 90%;
  }
}
</style>