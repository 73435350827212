<template>
  <section class="header section is-paddingless py-3" style="width: 100%">
    <div class="container">
      <nav
        class="navbar is-white"
        role="navigation"
        aria-label="main navigation"
      >
        <div class="navbar-brand">
          <router-link
            :to="{ name: 'Home' }"
            class="navbar-item"
            id="site-logo"
          >
            <img
              src="../assets/chinn_logo.png"
              alt="chinn logo"
              width="112"
              height="28"
            />
          </router-link>

          <a
            role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu">
          <div class="navbar-start">
            <router-link :to="{ name: 'Home' }" class="navbar-item"
              >Home</router-link
            >
            <router-link :to="{ name: 'Design' }" class="navbar-item"
              >Design</router-link
            >
            <router-link :to="{ name: 'Tech' }" class="navbar-item"
              >Tech</router-link
            >
          </div>

          <div class="navbar-end">
            <div class="navbar-item" id="toggle-navbar-pt">
              <router-link :to="{ name: 'About' }" class="navbar-item"
                >About</router-link
              >

              <router-link :to="{ name: 'Resume' }" class="navbar-item"
                >Resume</router-link
              >
              <a
                href="https://www.linkedin.com/in/hochinnfang"
                target="_blank"
                class="navbar-item is-link"
              >
                <span class="icon">
                  <i class="fab fa-linkedin" id="linkedin-icon"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppHeader",
  computed: {
    isHomePage() {
      return this.$routes.name === "Home";
    },
  },
};
</script>

<style>
.header {
  overflow: hidden;
  background-color: #fff;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 100;
}

.navbar-item {
  color: #0a0a0a !important;
}

a.navbar-item:hover {
  background-color: #f2f2f2 !important;
  color: #0a0a0a !important;
}
#site-logo:hover {
  background-color: #fff !important;
}

a.navbar-item:focus-within {
  background-color: #fff !important;
  color: #0a0a0a !important;
}

#linkedin-icon {
  color: #485fc7 !important;
}

.current-page {
  color: #485fc7 !important;
}
</style>